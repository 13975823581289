import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Tooltip } from "antd";
import {
  HomeOutlined,
  LogoutOutlined,
  CalculatorOutlined,
  LayoutFilled,
  FileSearchOutlined,
  UnorderedListOutlined,
  StockOutlined,
  AreaChartOutlined,
  TableOutlined,
  PieChartOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "store/auth/auth.slice";
import styled from "styled-components";
import { isNullable } from "lib/utils";
import { selectIsAuthenticated } from "store/auth/auth.selectors";

const { SubMenu } = Menu;

const StyleWrapper = styled.div`
  .ant-menu {
    border: none;
  }
`;

export interface MenuItem {
  logo: JSX.Element;
  name: string;
  link?: string;
  subMenu?: MenuItem[];
}

export interface MenuOptions {
  defaultOpenKeys?: string[];
  mode?:
    | "vertical"
    | "vertical-left"
    | "vertical-right"
    | "horizontal"
    | "inline"
    | undefined;
}

interface NavBarProps {
  /**
   * A callback function which will be invoked when a menu item is selected.
   */
  onClick?: () => void;
}

interface NavBarCreatorProps {
  spec: MenuItem[];
  options?: MenuOptions;
  onClick?: () => void;
}

const menuDefinition: MenuItem[] = [
  {
    name: "Home",
    logo: <HomeOutlined />,
    link: "/",
  },
  {
    name: "Valuation Models",
    logo: <LayoutFilled />,
    link: "/",
    subMenu: [
      {
        name: "Discounted Cash Flow",
        logo: <TableOutlined />,
        link: "/dcf",
      },
      {
        name: "Gordon Growth",
        logo: <AreaChartOutlined />,
        link: "/gordon",
      },
      {
        name: "Financial Services Firm",
        logo: <StockOutlined />,
      },
      {
        name: "Young/High-Growth Firm",
        logo: <StockOutlined />,
      },
    ],
  },
  {
    name: "Equity Research",
    logo: <FileSearchOutlined />,
    link: "/equity-research",
  },
  {
    name: "Valuation Model Picker",
    logo: <UnorderedListOutlined />,
    link: "/model-picker",
  },
  {
    name: "Option Pricing Calculator",
    logo: <CalculatorOutlined />,
    link: "/opcalc",
  },
  {
    name: "Portfolio Visualizer",
    logo: <PieChartOutlined />,
  },
];

const menuOptions: MenuOptions = {
  mode: "inline",
};

/**
 * Find the 'name' of a tab, given a relative path.
 *
 * @param relativePath
 *     A relative path, often the one that belongs to the current URL.
 *     Example relative paths include '/home', '/error/description', etc.
 *
 * @param menuDef
 *     An array of `MenuItem`s. The array is expected to define the app's navbar.
 *
 * @param cb
 *     Optionally provide a callback function, can be used to format the tab
 *     name that has been found.
 *
 * @param notFoundFallback
 *     Optionally define a string which will be used in case no tab name could
 *     be located with the given relative path. Defaults to an empty string.
 */
const resolveTabname = (
  relativePath: string,
  menuDef: MenuItem[],
  cb = (val: string) => val,
  notFoundFallback = ""
): string => {
  // store the name of the tab found, if any, here.
  let tabFound: string | undefined;

  // perform DFS to find the first MenuItem which has the same pathname
  // as the one provided in the argument.
  const findLink = (menus?: MenuItem[]): MenuItem | undefined => {
    if (!menus) return;

    return menus.find((menu) => {
      const foundMatchLink = menu.link === relativePath;

      if (foundMatchLink) {
        tabFound = menu.name;
      }

      return foundMatchLink || findLink(menu.subMenu);
    });
  };

  findLink(menuDef);

  return cb(tabFound || notFoundFallback);
};

export const NavBarCreator: React.FC<NavBarCreatorProps> = ({
  spec,
  options,
  onClick = () => {},
}: NavBarCreatorProps) => {
  const nameToKey = (name: string) => name.toLowerCase().split(" ").join("-");

  const location = useLocation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const [selectedTab, setSelectedTab] = useState(
    resolveTabname(location.pathname, spec, nameToKey, "Home")
  );

  // recalculate the tab selection if the page's relative path has changed.
  useEffect(() => {
    setSelectedTab(resolveTabname(location.pathname, spec, nameToKey, "Home"));
  }, [location.pathname, spec]);

  const menuItemCreator = (menuItem: MenuItem) => {
    const leafMenuItem = (item: MenuItem) => {
      const isDisabled = isNullable(item.link);

      return (
        <Menu.Item
          key={nameToKey(item.name)}
          icon={item.logo}
          disabled={isDisabled}
        >
          {item.link ? (
            <Link to={item.link}>
              <span>{item.name}</span>
            </Link>
          ) : (
            <Tooltip
              title="Feature under development"
              placement="right"
              mouseEnterDelay={0.5}
            >
              <span>{item.name}</span>
            </Tooltip>
          )}
        </Menu.Item>
      );
    };

    const subMenuWrapper = (parent: MenuItem) => (
      <SubMenu
        key={nameToKey(parent.name)}
        icon={parent.logo}
        title={
          <span>
            <span>{parent.name}</span>
          </span>
        }
      >
        {parent.subMenu ? parent.subMenu.map((item) => leafMenuItem(item)) : ""}
      </SubMenu>
    );

    return menuItem.subMenu ? subMenuWrapper(menuItem) : leafMenuItem(menuItem);
  };

  return (
    <div className="navbar">
      <Menu
        defaultOpenKeys={options?.defaultOpenKeys?.map(nameToKey) || []}
        mode={options?.mode || undefined}
        selectedKeys={[selectedTab]}
        onClick={({ key }) => {
          const resolvedKey = typeof key === "number" ? `${key}` : key;
          setSelectedTab(resolvedKey);
          onClick();
        }}
      >
        {spec.map((menuItemSpec) => menuItemCreator(menuItemSpec))}

        {isAuthenticated ? (
          <Menu.Item
            key="9"
            icon={<LogoutOutlined />}
            onClick={() => dispatch(logout())}
          >
            Sign out
          </Menu.Item>
        ) : (
          <Menu.Item
            key="9"
            icon={<LoginOutlined />}
            onClick={() => dispatch(login())}
          >
            Login / Signup
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
};

export const NavBar: React.FC<NavBarProps> = ({ onClick }) => (
  <StyleWrapper>
    <NavBarCreator
      spec={menuDefinition}
      options={menuOptions}
      onClick={onClick}
    />
  </StyleWrapper>
);
