import React from "react";
import styled from "styled-components";

const BottomFooter = styled.div`
  position: fixed;
  bottom: 0;
  padding: 1rem 0 3rem 0;
  width: 100%;

  display: flex;

  > * {
    padding: 0 1rem;
    border-right: 0.5px solid #929292;

    &:last-of-type {
      border-right: none;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpointMobile}) {
    position: relative;
    flex-direction: column;
    margin: 2rem 0;
    padding: 0;

    font-size: 1rem;

    border-top: 1px solid #929292;
    margin: 0 1rem;
    padding-top: 2rem;
    padding-left: 1rem;

    > * {
      border: none;
      padding: 0.5rem 0;
    }
  }
`;

const DashboardFooter = () => {
  return (
    <BottomFooter>
      <span>NYU Math Finance Group ©2020</span>
      <span>
        <a href="https://mfg-nyu.com" target="_blank" rel="noopener noreferrer">
          About Us
        </a>
      </span>
    </BottomFooter>
  );
};

export default DashboardFooter;
