import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModuleLayout from "components/shared/ModuleLayout";
import ModuleHeader from "components/shared/ModuleHeader";
import styled from "styled-components";
import { SearchOutlined, RiseOutlined, BlockOutlined } from "@ant-design/icons";
import { PageHeader, Button } from "antd";
import FeatureRequestModal from "./FeatureRequestModal";
import UpcomingFeaturesModal from "./UpcomingFeaturesModal";
import DashboardFooter from "./DashboardFooter";

const DashboardContentContainer = styled.div`
  position: relative;
  min-height: 70vh;
  @media (max-width: ${(props) => props.theme.breakpointTablet}) {
    margin-bottom: 2rem;
  }
`;

const FeatureContainer = styled.div`
  margin-top: 0.5rem;

  .inner-container {
    display: flex;
    flex-flow: row wrap;
    margin-top: 1.5rem;

    .feature-inner {
      margin: 2rem;
    }
  }
`;

const FeatureWrapper = styled.div`
  .feature-inner {
    background: white;
    padding: 2rem 1rem 1rem;
    border-radius: 5px;
    transition: box-shadow 0.5s;

    display: flex;
    align-items: flex-end;
    position: relative;

    min-height: 11rem;
    min-width: 16rem;
    max-width: 18rem;
    width: 100%;

    box-shadow: 5px 10px 23px 0 rgb(74 76 84 / 28%);

    &:hover {
      box-shadow: 10px 16px 28px 0 rgba(32, 33, 36, 0.28);
    }

    .decorator {
      color: #333333;
      position: absolute;
      top: 0;
      right: 16px;
      font-size: 5rem;
      opacity: 0.15;
      transition: all 0.5s;
    }
  }

  a {
    transition: all 0.1s;
    color: #333333;
    font-size: 1.5rem;

    &:hover {
      color: #0e3b90;
      font-size: 1.6rem;

      .decorator {
        opacity: 0.3;
      }
    }
  }
`;

const FeatureHeaderWrapper = styled.div`
  .ant-page-header {
    padding: 0;
    margin-bottom: 1.5rem;

    @media (max-width: ${(props) => props.theme.breakpointMobile}) {
      margin-bottom: 2rem;
    }
  }

  .ant-page-header-heading-title {
    font-size: 1.8rem;
    font-weight: 400;

    @media (max-width: ${(props) => props.theme.breakpointMobile}) {
      font-size: 2.2rem;
    }
  }

  .ant-btn {
    border: none;
    border-radius: 20px;
    box-shadow: 9px 9px 20px 0px rgb(74 76 84 / 19%);

    @media (max-width: ${(props) => props.theme.breakpointMobile}) {
      margin-top: 1rem;
    }
  }

  .ant-btn-primary {
    font-weight: 600;
  }
`;

const Dashboard: React.FC = () => {
  const [modalVisiblity, setModalVisibility] = useState({
    upcomingFeatures: false,
    featureRequest: false,
  });

  const closeAllModals = () =>
    setModalVisibility({
      featureRequest: false,
      upcomingFeatures: false,
    });

  return (
    <ModuleLayout>
      <ModuleHeader title="Welcome to the MFG App" showBeta={false} />

      <DashboardContentContainer>
        <FeatureContainer>
          <FeatureHeaderWrapper>
            <PageHeader
              backIcon={false}
              title="Features"
              extra={[
                <Button
                  key="2"
                  onClick={() =>
                    setModalVisibility({
                      featureRequest: true,
                      upcomingFeatures: false,
                    })
                  }
                >
                  Request a feature
                </Button>,
                <Button
                  key="1"
                  type="primary"
                  onClick={() =>
                    setModalVisibility({
                      featureRequest: false,
                      upcomingFeatures: true,
                    })
                  }
                >
                  Upcoming features
                </Button>,
              ]}
            />
          </FeatureHeaderWrapper>

          <FeatureRequestModal
            visible={modalVisiblity.featureRequest}
            onCancel={closeAllModals}
            onOk={() => {
              closeAllModals();
            }}
          />

          <UpcomingFeaturesModal
            visible={modalVisiblity.upcomingFeatures}
            onCancel={closeAllModals}
          />

          <span style={{ fontSize: "1.2rem" }}>
            Here are a few features to get you started:
          </span>
          <div className="inner-container">
            <FeatureWrapper>
              <Link to="./dcf">
                <span className="feature-inner">
                  Basic company <br /> valuation model
                  <span className="decorator">
                    <BlockOutlined />
                  </span>
                </span>
              </Link>
            </FeatureWrapper>

            <FeatureWrapper>
              <Link to="./gordon">
                <span className="feature-inner">
                  Gordon <br /> growth model
                  <span className="decorator">
                    <RiseOutlined />
                  </span>
                </span>
              </Link>
            </FeatureWrapper>

            <FeatureWrapper>
              <Link to="./equity-research">
                <span className="feature-inner">
                  Search for <br /> a company
                  <span className="decorator">
                    <SearchOutlined />
                  </span>
                </span>
              </Link>
            </FeatureWrapper>

            <FeatureWrapper>
              <Link to="./opcalc">
                <span className="feature-inner">
                  Black-Scholes <br /> option calculator
                  <span
                    className="decorator"
                    style={{ marginTop: "-1.7rem", fontSize: "5.8rem" }}
                  >
                    σ
                  </span>
                </span>
              </Link>
            </FeatureWrapper>
          </div>
        </FeatureContainer>

        <DashboardFooter />
      </DashboardContentContainer>
    </ModuleLayout>
  );
};

export default Dashboard;
