import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsAuthenticating } from "store/auth/auth.selectors";
import LoadingOverlay from "components/LoadingOverlay";

const Login = React.lazy(() => import("./components/Auth/Login"));
const Home = React.lazy(() => import("./components/Home"));

const App: React.FC = () => {
  const isAuthenticating = useSelector(selectIsAuthenticating);

  const loadingFallback = (
    <span data-testid="loading-fallback">
      <LoadingOverlay />
    </span>
  );

  if (isAuthenticating) {
    return loadingFallback;
  }

  return (
    <Suspense fallback={loadingFallback}>
      <Switch>
        <Route path="/auth" component={Login} />
        <Route path="/" component={Home} />
      </Switch>
    </Suspense>
  );
};

export default App;
