import React, { Suspense, useState } from "react";
import { Layout, Drawer, Button } from "antd";
import { Switch, Route, Link } from "react-router-dom";
import useWindowSize from "hooks/useWindowSize";
import { UnorderedListOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { NavBar } from "./Navbar/NavBar";
import { Error } from "./Error";
import LoadingOverlay from "./LoadingOverlay";
import logo from "../assets/logo512.png";
import Dashboard from "./Dashboard/Dashboard";
import ImageWithFallback from "./shared/ImageWithFallback";

const Dcf = React.lazy(() => import("./Dcf/Dcf"));
const Equity = React.lazy(() => import("./Equity/Equity"));
const GordonGrowthModel = React.lazy(() =>
  import("./Gordon/GordonGrowthModel")
);
const OptionCalculator = React.lazy(() => import("./Options/OptionCalculator"));
const ModelPicker = React.lazy(() => import("./ModelPicker/ModelPicker"));

const { Sider, Content } = Layout;

const NavBarToggleWrapper = styled.div`
  position: fixed;
  top: 1.5rem;
  left: 0;
  z-index: 2;

  button {
    background: #0e2c58;
    color: white;
    border: none;
    border-radius: 0 5px 5px 0;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
    width: 40px;
    height: 45px;

    &:hover {
      background: #2359a8;
      color: white;
    }
  }
`;

const LogoWrapper = styled.div`
  background: white;
  display: flex;
  padding: 1rem;

  img {
    /** forcibly override the width settings inside ImageWithFallback */
    width: 30% !important;
  }
`;

const Logo = () => (
  <LogoWrapper>
    <Link to="/">
      <ImageWithFallback src={logo} alt="MFG Logo" dimension={10} delay={250} />
    </Link>
  </LogoWrapper>
);

const Home: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const windowSize = useWindowSize();

  const shouldCollapseNav = (windowSize.width || 0) <= 990;

  const NavContainer = shouldCollapseNav ? (
    // small layout
    <Drawer
      visible={isDrawerOpen}
      placement="left"
      width="300"
      onClose={() => setIsDrawerOpen(false)}
      bodyStyle={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: "2.2rem",
      }}
    >
      <Logo />
      <NavBar
        onClick={() => {
          setIsDrawerOpen(false);
        }}
      />
    </Drawer>
  ) : (
    // desktop-level layout
    <Sider
      breakpoint="lg"
      width={250}
      collapsedWidth="0"
      onBreakpoint={() => setIsDrawerOpen(false)}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
      }}
    >
      <Logo />
      <NavBar />
    </Sider>
  );

  return (
    <Layout
      style={{
        minHeight: "100vh",
        marginLeft: shouldCollapseNav ? "0px" : "250px",
      }}
    >
      <Helmet>
        <title>MFG - Home</title>
        <meta
          name="description"
          content="Home of the MFG App, an all-in-one finance study platform."
        />
      </Helmet>

      {NavContainer}

      <Layout className="main-container">
        {shouldCollapseNav ? (
          <NavBarToggleWrapper>
            <Button
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              icon={<UnorderedListOutlined />}
            />
          </NavBarToggleWrapper>
        ) : null}
        <Content>
          <Suspense fallback={<LoadingOverlay />}>
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route path="/dcf" component={Dcf} />
              <Route path="/equity-research" component={Equity} />
              <Route path="/gordon" component={GordonGrowthModel} />
              <Route path="/opcalc" component={OptionCalculator} />
              <Route path="/model-picker" component={ModelPicker} />
              <Route component={Error} />
            </Switch>
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Home;
